import 'preloader.scss';

global.preloader = {
  el: null,
  files: [],
  show() {
    this.el = document.createElement('div');
    this.el.className = 'preloader';

    const spinner = document.createElement('div');
    spinner.className = 'preloader-spinner';
    this.el.appendChild(spinner);

    document.body.appendChild(this.el);
  },
  add(filename, async = false) {
    this.files.push({
      src: filename,
      async,
    });
  },
  start() {
    this.files.forEach(({ src, async }) => {
      const tag = document.createElement('script');
      tag.src = src;
      tag.async = async;

      document.body.appendChild(tag);
    });

    const check = setInterval(() => {
      // eslint-disable-next-line no-underscore-dangle
      if (global._appReady) {
        document.body.removeChild(this.el);
        clearInterval(check);
      }
    }, 50);
  },
};
